.button {
    color: #FEFCFF;
    border: 1px solid #6B4380;
    color: #6B4380;
    border-radius: 8px;
    margin: 5px;
    height: 37px ;
    width: 60px;
    cursor: pointer;
    font-size: 15px;
}

.button:hover {
    background-color: rgb(96, 61, 228);
    transform: scale(1.05); 
}

.button:active {
transform: translateY(1px);
}