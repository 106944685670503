.login{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh; 
    text-align: center;

    .cabecalho{
        width: 100%;
        height: auto;

        .navbar-login{
            display: flex;
            align-items: flex-start;
        }

    }
    
    .container{
        flex: 1; 
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        background-image: url("fundo.jpg"); 

        .quadro{
        
            border: 0.5px solid rgb(226, 224, 224);
            border-radius: 8px;
            box-shadow: 2px 4px 10px 1px rgba(151, 151, 151, 0.47);
            padding: 40px 80px 120px 80px;

            display: flex;
            align-items: center;
            flex-direction: column;
            background-color: white;
            width: 604px;
            height: 256px;

            max-width:  604px;
            max-height: 256px;

            .logo{
                font-size: 35px;
                font-weight: bold;
                color: #6B4380;
            
            }

            .coteudo{

                width: 604px;
                height: 200px;

            }
    }
        
        
    }
}