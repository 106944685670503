.popup {
    position: fixed;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.788);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    z-index: 199999;

    .popup-inner{
        position: relative;
        padding: 32px;
        max-width: 1040px;
        max-height: 950px;
        background-color: white;
        border-radius: 7px;
        overflow-x: auto;
        .icone-popup-fechar{
            display: flex;
            width: 100%;
            justify-content: flex-end ;
            cursor: pointer;
        }
        
        .conteudo-popUp-fechar{
            .popUpCanLogin{
            }

            .popUpConteudoCamera{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 400px;
                height: 500px;
                .div-step-1{
                    height: 100%;
                    .texto-titulo{
                        background-color: #6B4380;
                        color: #fff;
                        height: 30px;
                        margin-top: 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .bloco-camera{
                        margin-top: 10px;
                        border: 4px solid #6B4380;
                        height: 470px;
                        width: 380px;
                    }
                }
            }

            .popUpConteudoScan{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 400px;
                height: 515px;
                .div-step-1{
                    height: 100%;
                    .texto-titulo{
                        background-color: #6B4380;
                        color: #fff;
                        height: 30px;
                        margin-top: 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .bloco-camera{
                        margin-top: 10px;
                        border: 4px solid #6B4380;
                        height: 470px;
                        width: 350px;
                    }
                }
            }
        }
    }
}