.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(233, 231, 231);
  height: auto;
  background-color: white;

  .top {
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #FAD40C;
    background: #6B4380; 
    color: white ;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5)
  }


}
