.capa-card-list{
    width: 100%;

    .borda-historico{

        border-radius: 8px;
        border: 1px solid #BCB0B0;
        padding: 10px;
        margin-top: 10px;
        margin-right: 10px;
        .cabeca-historico{
            display: flex;
            width: 500px;
            justify-content: space-around;
            border-bottom:1px solid #BCB0B0;
            color:rgb(29, 28, 28);
            
        }
        .corpo-historico{
            display: flex;
            width: 500px;
            justify-content: space-between;
            border-bottom:1px solid #e0dbdb;
            color: rgb(78, 78, 78);
            padding: 0px 5px 0px 5px;

            height: auto;
    
        }
    }

    .centraliza_loader{
        display: flex;
        justify-content: center;
        .loader-card-list{
            top: 50%;
            width: 15%;
            height: 60px;
            justify-content: center;
            display: flex;
            background-color: rgba(248, 245, 245, 0.788);
            position: fixed;
            border-radius: 8px;
            z-index: 100;
        }
    }


    .titulo-card{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: rgb(78, 78, 78);
        font-size: 15px;
        margin-bottom: 20px;
    }

    .capa-conteudo-card{
        display: flex;
        justify-content: center;
        .conteudo-card{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-height: 100vh;
            overflow: hidden;
            justify-content: center;
            align-items: center;
        }
    }


    @media (max-width: 600px) {
        .conteudo-card {
            column-count: 1;
        }
    }

    .popUpConteudo{
        display: flex;
        max-width: 300px;
        flex-direction: column;

        .icones-form{
            color: rgb(78, 78, 78);
        }

        .iconePhoto{
            display: flex;
            margin: 7px;
            justify-content: center;
            align-items: center;

            .conteudo{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .iconCameraEntrega{
                cursor: pointer;
                font-size: 50px;
                border-radius: 8px;
                border: 1px solid #BCB0B0;
                color: rgb(78, 78, 78);
            }


            .listaFotos {
                display: flex;
                flex-wrap: wrap;
            }

            .listaFotos img {
                max-width: 100%; 
                height: auto; 
                width:  50px;
            }

            .listaFotos span {
                cursor: pointer;
            }

        }  

        .codigoRegistro{
            font-size: 13px;

        }

        .buttonRegistrar{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }

        .codigo-titulo-popup{
            background-color: #6B4380;
            color: #fff;
            height: 30px;
            width: 300px;
            margin-top: 10px;
            border-radius: 5px;

            .texto-titulo-popup{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }

        .dados-popup{
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 10px;

            .texto-titulo-popup{
                margin-top: 3px;
                display: flex;
                flex-direction: row;
                height: 100%;

                .label{
                    color:rgb(29, 28, 28);
                }
                .conteudo{
                    color:rgb(78, 78, 78);
                    margin-left: 3px;
                }
            }
        }

        .abraca-sec-volume{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            margin-top: 20px;
            
            .sec-volume{
                width: 180px;
                height: 70px;
                padding: 10px;
                display: flex;
                border: 1px solid #6B4380;
                background-color: #6B4380;
                border-radius: 8px;
                flex-direction: column;

                .volume-container {
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    justify-content: space-evenly;
                    gap: 8px;
                    height: 80%;
                    color: #fff;
                  }
                  
                  .volume-input {
                    width: 50px;
                    text-align: center;
                    font-size: 16px;
                    padding: 4px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                  }
                  
                  .btn {
                    font-size: 35px;
                    color: white;
                    border: none;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  
                  .btn:hover {
                    background: #b0b1b3;
                  }
                  

                .titulo{
                    color: white;
                    font-size: 15px;

                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .conteudo{
                    color: white;
                    font-size: 25px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .iconesEntrega{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            //margin-top: 20px;
            //margin-left: 20px;
            .iconCameraEntrega{
                font-size: 50px;
                border-radius: 8px;
                border: 1px solid #BCB0B0;

            }
            .iconAssinaturaEntrega{
                font-size: 50px;
                border-radius: 8px;
                border: 1px solid #BCB0B0;

            }
        }

    }

}