/* Container principal */
.scannet-todo-conteudo{
    height: 100%;
    .scanner-container {
        /*height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;*/
        justify-content: center;
        background-color: black;
        overflow: hidden;
        height: 100%;

        display: grid;
        place-items: center;
    }
}

.scanner-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scanner-video ,
.overlay {
  grid-area: 1 / 1;
}


.overlay {
    position: relative;
    width: 100%;
    height: 100%;
    transition: background 0.5s ease; 

}

.scanner-box {
    width: 100%;
    height: 100%;
    background: transparent;
    
}
.scanner-box::before,
.scanner-box::after,
.scanner-box div::before,
.scanner-box div::after {
    content: "";
    position: absolute;
    margin: 30px;
    width: 20px;
    height: 20px;
    border: 5px solid #6B4380; 
}

/* Canto superior esquerdo */
.scanner-box::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
}

/* Canto superior direito */
.scanner-box::after {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
}

/* Canto inferior esquerdo */
.scanner-box div::before {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
}

/* Canto inferior direito */
.scanner-box div::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
}

.scanner-box.scanned {

    width: 100%;
    height: 100%;
    background: transparent;
}

.scanner-box.scanned::before,
.scanner-box.scanned::after,
.scanner-box.scanned div::before,
.scanner-box.scanned div::after {
    content: "";
    position: absolute;
    width: 20px; /* Tamanho do canto */
    height: 20px;
    border: 5px solid green; /* Cor e espessura da borda */
}

/* Canto superior esquerdo */
.scanner-box.scanned::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
}

/* Canto superior direito */
.scanner-box.scanned::after {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
}

/* Canto inferior esquerdo */
.scanner-box.scanned div::before {
    bottom: 0;
    left: 0;
    border-right: none;
    border-top: none;
    position: absolute;
}

/* Canto inferior direito */
.scanner-box.scanned div::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
    position: absolute;
}


.scanner-result {
    position: absolute;
    bottom: 120px;
    color: black;
    font-size: 18px;
    text-align: center;
}
