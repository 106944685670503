.componente-assinatura{
    padding-top: 10px;

    .bloco-assinatura{
        margin: 5px;

        .espaco-assinatura{
            border: 1px solid #BCB0B0;
            border-radius: 5px;
            margin-bottom: 10px;
            
        }
    }

    .bottuns-assinatura{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

}
