.telaAimada-finalizada{
    display:flex;
    flex-direction: column;

    width: 400px;
    height: 500px;

    .material-icons{
        display: flex;
        justify-content: flex-end;
    }

    .popUp-tela-geral-finalizada{
        margin-top: 15px;
        height: 100%;
        .div-abraca{
            height: 100%;
            .loader{

            }
            .conteudo{
                height: 80%;



                .confirma{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .div-step-1{

                        .loader{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 95%;
                            .icone{
                                width: 100px;
                            }
                        }

                        .yes{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 95%;
                            .icon{
                                font-size: 65px;
                                color: rgb(66, 175, 60);

                            }
                        }

                    }

                }
            }

            .div-step-1{
                .texto-titulo{
                    background-color: #6B4380;
                    color: #fff;
                    height: 30px;
                    margin-top: 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .bloco-camera{
                    margin-top: 10px;
                    border: 4px solid #6B4380;
                    margin-bottom: 50px;

                }
            }

            .botoes{
                display: flex;
                justify-content: center;
            }
        }

    }
}