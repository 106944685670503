.settings {
  display: flex;
  width: 100%;

  .settingsCont {
    flex: 6;
    .box {
      margin: 10px;
      align-items: center;
      border: 1px solid rgb(179, 179, 179);
      border-radius: 2px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
    }
    .main {
      display: flex;

      .subOptions {
        justify-content: space-between;
        width: 150px;
        height: 340px;
        .subOption {
          display: flex;
          padding: 10px;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #d4d4d4;
          }
        }
      }
      .options {
        display: flex;
        flex-direction: column;
        width: 800px;
        height: 340px;
        .top {
          margin: 15px;
          align-self: center;
        }
        .middle {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .option {
          display: flex;
          align-items: center;

          margin-left: 5px;
          padding: 3px;
          .inputButton {
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;
            cursor: pointer;

            input {
              border: none;
              outline: none;
              background: transparent;
              font-size: 16px;
              font-weight: 600;

              &::placeholder {
                color: rgb(145, 145, 145);
              }
            }

            &:hover {
              color: #7451f8;
            }
          }
          .optionTitle {
            font-size: 16px;
          }
        }
      }
    }
  }
}
