.home{
        height: 100%;
    .homeCont{
        width: 100%;
        height: 100%;
        .content{
            display: flex;
            justify-content: center; //cetraliza verticalment
            width: 100%;
            height: 100%;
            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
            }
        }
    }
}