.telaAimada{
    display:flex;
    flex-direction: column;

    width: 400px;
    height: 450px;

    .material-icons{
        display: flex;
        justify-content: flex-end;
    }

    .popUp-tela-geral{
        height: 100%;
        .div-abraca{
            height: 100%;
            width: 100%;
            .loader{

            }

            .conteudo{
                height: 100%;
                width: 100%;

            }

            .div-step-1{
                height: 100%;
                width: 100%;
                .texto-titulo{
                    background-color: #6B4380;
                    color: #fff;
                    height: 30px;
                    margin-top: 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .bloco-camera{
                    margin-top: 10px;
                    border: 4px solid #6B4380;

                    height: 360px;

                }

                .loader{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 95%;
                    .icone{
                        width: 100px;
                    }
                }

                .yes{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 95%;
                    .icon{
                        font-size: 65px;
                        color: rgb(66, 175, 60);

                    }
                }
            }

            .botoes{
                display: flex;
                justify-content: center;
            }
        }

    }
}