.paginaGrandesEncomendas{
    display: flex;

    .popUp-date{
        .loader-grandes-encomendas{
            top: 50%;
            width: 30%;
            height: 60px;
            justify-content: center;
            display: flex;
            background-color: rgba(248, 245, 245, 0.788);
            position: fixed;
            border-radius: 8px;
            z-index: 100;
        }

    }
    
    .contGrandesEncomendas{
        flex: 6;
        
        .content{
            display: flex;
            justify-content: center; 
            flex-direction: column;
            margin: 40px 10px 20px 5px;

            .loader-grandes-encomendas{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            .cards{
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;

                .icone-calendario{
                    display: flex;
                    justify-content: center;
                }
            }

            .nome-funcionario{
                font-size: 15px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 100%;
                color: rgb(78, 78, 78);
            }
            .botoes-filtro{
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                color: rgb(78, 78, 78);

                .botao{
                    padding: 10px;
                    margin-left: 15px;
                    background-color: white;
                    border-radius: 8px;
                    color: #6B4380;
                    cursor: pointer;
                }
                .botao.ativo {
                    padding: 10px;
                    border: 2px solid #6B4380;
                    background-color: #6B4380;
                    border-radius: 8px;
                    color: white;
                    margin-left: 15px;
                    cursor: pointer;
                }
            }

            .tituloGrandesEncomendas{
                font-size: 20px;
                display: flex;
                color: rgb(78, 78, 78);

               .iconTitulo{
                 color: #6B4380;
                 margin-right: 5px;
               }
            }

            .botaoGrandesEncomendas{
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: flex-end;
                color: #6B4380;
                cursor: pointer;

                .iconAddEntrega{
                    font-size: 30px;
                }
            }


            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }

            .popUpConteudoCamera{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .sec-assinatura{
                    margin-left: 5px;
                    margin-top: 10px;
                }
            }
    
        }
    }


}