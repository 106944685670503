.conteudo-tela-login{
    /*position: relative;
    width: 100%;
    max-width: 604px; 
    max-height: 256px; 

    overflow: hidden; */

    position: relative;
    width: 100%;
    max-width: 604px;
    max-height: 256px;
    overflow: hidden;
    height: 100vh; /* ou outra altura adequada */
    .conteudo{
            
        /*display: flex;
        align-items: center;
        justify-content: center;
        width: 604px;
        height: 200px;
        transform: translate(-50%, -50%);*/

        position: absolute;

        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 604px;
        height: 200px;

        .div-step-1{
            height: 100%;
            width: 100%;
            .loader{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 95%;
                .icone{
                    width: 100px;
                }
            }

        }


        .placa{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #6B4380;
            border-radius: 8px;
            width: 350px;
            height: 150px;

            box-shadow: 
            inset 4px 4px 8px rgba(0, 0, 0, 0.4), 
            inset -4px -4px 8px rgba(255, 255, 255, 0.2), 
            3px 3px 5px rgba(0, 0, 0, 0.5); 

            margin-right: 20px;

            .escrita{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 60px;
                color: white;

            }
        }

        
        .quadro-login{
                        
            display: flex;
            flex-direction: column;

            border-radius: 8px;
            margin-left: 20px;

        }
    }


}